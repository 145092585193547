import { createSlice } from "@reduxjs/toolkit"
import { Dispatch } from "redux"
import type { PayloadAction } from "@reduxjs/toolkit"
import * as API from "../../util/apiClient"
import * as GraphQL from "../../graphql"
import { Status } from "../../util/types"

export interface commEmailListView {
  conversations: Status<GraphQL.SearchConversationsQuery>,
  page: number,
}

// Initialize state
const initialState: commEmailListView = {
  conversations: "init",
  page: 1,
}

export const commEmailListViewSlice = createSlice({
  name: "commEmailListView",
  initialState,
  reducers: {
    setConversations: (
      state,
      action: PayloadAction<Status<GraphQL.SearchConversationsQuery>>,
    ) => ({
      ...state,
      conversations: action.payload,
    }),
    setPage: (
      state,
    ) => ({
      ...state,
      page: state.page + 1,
    }),
    resetPage: (
      state,
    ) => ({
      ...state,
      page: 1,
    }),
    resetConversations: (state) => ({ ...state, conversations: "init" }),
    apendConversations: (
      state,
      action: PayloadAction<Status<GraphQL.SearchConversationsQuery>>,
    ) => ({
      ...state,
      conversations: action.payload,
    }),
  },
})

export const {
  setConversations,
  resetConversations,
  setPage,
  resetPage,
} = commEmailListViewSlice.actions
export default commEmailListViewSlice.reducer

// Add Accounts to Lists Modal Slice Thunks
export const fetchEmailsList = (
  params: GraphQL.SearchConversationsQueryVariables,
) => async (
  dispatch: Dispatch,
): Promise<void> => {
  dispatch(setConversations("loading"))

  const emailListResults = await API.fetchConversations(params)

  dispatch(setConversations(emailListResults))
}

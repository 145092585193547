import React from "react"
import { TFunction } from "i18next"
import { GridColDef, GridValidRowModel } from "@mui/x-data-grid-pro"
import daysjs from "dayjs"
import { Chip, Typography } from "@mui/material"
import SocialAvatar from "../SocialAvatar"
import * as Constant from "../../util/constant"
import { CommunicationGroupNetworkAccountLabel } from "../../graphql"
import GroupAccountsTableContextMenu from "./GroupAccountsTableContextMenu"

export function getColumnHeaders(translate: TFunction): GridColDef[] {
  // Create the columns
  return [
    {
      field: "account",
      headerName: translate("ACCOUNT"),
      sortable: false,
      renderCell: (params) => (
        <SocialAvatar { ...params.row.account } />
      ),
      disableColumnMenu: true,
      resizable: false,
      width: 275,
    },
    {
      field: "contactInfo",
      headerName: translate("CONTACT INFO"),
      sortable: false,
      renderCell: (params) => (
        <div>
          <Typography className="contact-info-name">
            { params.row.contactInfo.firstName }
            &nbsp;
            { params.row.contactInfo.lastName }
          </Typography>
          <Typography className="contact-info-email">
            { params.row.contactInfo.email }
          </Typography>
        </div>
      ),
      disableColumnMenu: true,
      resizable: false,
      width: 250,
    },
    {
      field: "labels",
      headerName: translate("LABELS"),
      sortable: false,
      renderCell: (params) => (
        <div>
          { params.row.labels.forEach((label: CommunicationGroupNetworkAccountLabel) => {
            <Chip label={ label.label } onDelete={ () => { } } />
          }) }
        </div>
      ),
      disableColumnMenu: true,
      resizable: false,
      width: 350,
    },
    {
      field: "addedToGroup",
      headerName: translate("ADDED TO GROUP"),
      sortable: false,
      renderCell: (params) => (
        <Typography className="table-add-to-group">{ params.row.addedToGroup }</Typography>
      ),
      disableColumnMenu: true,
      resizable: false,
      width: 200,
    },
    {
      field: "groupsContextMenu",
      headerName: "",
      sortable: false,
      renderCell: (params) => (
        <GroupAccountsTableContextMenu params={ params } />
      ),
      disableColumnMenu: true,
      width: 50,
    },
  ]
}

export function getRowData(row: { [ k: string ]: any }): GridValidRowModel | null {
  const { socialAccount } = row
  const { socialAccountStatistics: stats } = socialAccount

  return {
    id: row.id,
    account: {
      id: socialAccount.id,
      followers: stats.followers,
      fullName: socialAccount.name || "",
      isBlacklisted: socialAccount.personality?.blacklist || false,
      isNSFW: row.isNsfw,
      profilePictureUrl: socialAccount.profilePictureUrl,
      username: socialAccount.userName,
    },
    contactInfo: {
      firstName: socialAccount.contact?.firstName || "First Name",
      lastName: socialAccount.contact?.lastName || "Last Name",
      email: socialAccount.primaryEmail?.address || "No Prmary Email",
    },
    labels: row.labels,
    addedToGroup: daysjs.unix(row.created).format(Constant.MONTH_DAY_ABBR_FORMAT),
  }
}

import React from "react"
import { AddCircleOutline, MoreHoriz } from "@mui/icons-material"
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material"
import { useTranslation } from "react-i18next"
import { GridRenderCellParams, GridTreeNodeWithRender } from "@mui/x-data-grid"
import { useParams } from "react-router-dom"
import Dropdown from "../Dropdown"
import "./group-accounts-body.sass"

type Props = {
  params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>
}

/**
 * Handles creating and managing the context menu for the Communication - Group Accounts
 * @param params The row information in the table
 * @returns The jsx for creating and dsplaying the context menu
 */
export default function GroupAccountsTableContextMenu({
  params,
} : Props) {
  // Extract translation function in order to ensure proper translation of text for various languages
  const { t: translate } = useTranslation([], { keyPrefix: "component.GroupAccountsBody" })

  // Get URL parameters
  const { vanity } = useParams()

  /**
   * Function to handle what happens when the "View Account" context menu item is clicked on
   */
  const onViewAccount = () => {
    // Open in another tab
    window.open(`/${ vanity }/search/${ params.row.account.id }/insights`, "_blank")
  }

  /**
   * Function to handle what happens when the "Edit Contact Info" context menu item is clicked on
   */
  const onEditContactInfo = () => {
    // Place holder
  }

  return (
    <Dropdown
      buttonType="custom"
      customButtonChildren={ <MoreHoriz color="primary" /> }
      placement="left"
    >
      <List disablePadding={ true }>
        <ListItemButton onClick={ onViewAccount }>
          <ListItemIcon>
            <AddCircleOutline className="list-item-icon" />
          </ListItemIcon>
          <ListItemText primary={ translate("View Account") } />
        </ListItemButton>
        <ListItemButton onClick={ onEditContactInfo }>
          <ListItemIcon>
            <AddCircleOutline className="list-item-icon" />
          </ListItemIcon>
          <ListItemText primary={ translate("Edit Contact Info") } />
        </ListItemButton>
      </List>
    </Dropdown>

  )
}

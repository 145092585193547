import React from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import "./communications-body.sass"

import { EditOutlined, Link as LinkIcon } from "@mui/icons-material"
import dayjs from "dayjs"
import SearchBar from "../SearchBar"

import { useSelector, useDispatch } from "../../state/hooks"
import { Scope } from "../../util/types"
import LoadingIndicatorCard from "../LoadingIndicatorCard"
import ErrorHandler from "../ErrorHandler"
import * as Constant from "../../util/constant"
import IconButton from "../IconButton"
import CommunicationsEmailListView from "./CommunicationsEmailListView"
import { seteditCommGroupID, setCommGroupModalOpen } from "../../state/ModalCommGroupSlice"

export default function CommunicationsBody() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.CommunicationsBody" })
  const { commGroupID } = useParams()
  const dispatch = useDispatch()
  const { scopes } = useSelector(({ user: userSlice }) => userSlice)
  const { communicationGroup } = useSelector(({ communicationsPage }) => communicationsPage)

  if (communicationGroup === "init" || communicationGroup === "loading") {
    return <LoadingIndicatorCard />
  }
  if (communicationGroup.status === "error") {
    return <ErrorHandler />
  }

  const onSearchInput = () => {
    // Placeholder
  }

  const onEditClick = () => {
    if (commGroupID) {
      dispatch(seteditCommGroupID(commGroupID))
      dispatch(setCommGroupModalOpen(true))
    }
  }

  const {
    created,
  } = communicationGroup.payload.communicationGroup

  return (
    <div className="cp_component_communications-body-main-container">
      <div className="header-section">
        <SearchBar className="search-bar" onChange={ onSearchInput } />
        <div className="details-container">
          <p className="created-label">
            { `${ translate("Created") } ${ dayjs(created * 1000)
              .format(Constant.MONTH_DAY_ABBR_FORMAT) }` }
          </p>
          <div className="campaign-page-button">
            <LinkIcon />
            <p>{ translate("Campaign") }</p>
          </div>
          { scopes.includes(Scope.COMMUNICATIONS_MANAGEMENT) && (
            <IconButton variant="outlined" onClick={ onEditClick }>
              <EditOutlined />
            </IconButton>
          ) }
        </div>
      </div>
      <div className="communications-body-wrapper">
        <CommunicationsEmailListView />
        <div className="communications-email-conversation-detail">Placeholder 2</div>
      </div>
    </div>
  )
}

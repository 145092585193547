import { createSlice } from "@reduxjs/toolkit"

// Comms create template modal slice Interface and Initial State
export interface CommsCreateTemplateModalState {
  open: boolean
}

const initialState: CommsCreateTemplateModalState = {
  open: false,
}

// Campaign Page Slice
export const CommsCreateTemplateModal = createSlice({
  name: "CommsCreateTemplateModal",
  initialState,
  reducers: {
    openCommsCreateTemplate: (
      state,
    ) => ({
      ...state,
      open: true,
    }),
    closeCommsCreateTemplate: (
      state,
    ) => ({
      ...state,
      open: false,
    }),
  },
})

export const {
  openCommsCreateTemplate,
  closeCommsCreateTemplate,
} = CommsCreateTemplateModal.actions
export default CommsCreateTemplateModal.reducer

import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import "./style.sass"
import Modal from "../Modal"
import Input from "../Input"
import { useDispatch, useSelector } from "../../state/hooks"
import { closeCommsCreateTemplate } from "../../state/commsCreateTemplateModal"
import RichTextEditor from "../RichTextEditor"

export default function ModalCommsCreateTemplate() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.ModalCommsCreateTemplate" })
  const { t: tCommon } = useTranslation([], { keyPrefix: "common" })

  const { open } = useSelector(({ commsCreateTemplateModal }) => commsCreateTemplateModal)
  const [ editorContent, setEditorContent ] = useState("")
  const [ template, setTemplate ] = useState("")
  const [ description, setDescription ] = useState("")
  const [ subject, setSubject ] = useState("")
  const dispatch = useDispatch()

  const handleSubmit = () => {
    // placeholder
  }

  const handleClose = () => {
    dispatch(closeCommsCreateTemplate())
    setEditorContent("")
    setTemplate("")
    setDescription("")
    setSubject("")
  }

  const canSubmit = editorContent.length && template.length && subject.length

  return (
    <Modal
      className="cp_component_modal-comms-create-template"
      title={ translate("Create New Communications Template") }
      primaryLabel={ translate("Create Template") }
      secondaryLabel={ tCommon("Cancel") }
      primaryAction={ handleSubmit }
      secondaryAction={ handleClose }
      closeAction={ handleClose }
      open={ open }
      disabled={ !canSubmit }
      maxWidth="lg"
    >
      <div className="content-container">
        <h2>{ translate("Message Template") }</h2>
        <Input
          value={ template }
          onChange={ (e) => setTemplate(e.target.value) }
          label={ `${ translate("Template Name") }*` }
          placeholder={ translate("Enter Template Name") }
        />
        <Input
          value={ description }
          onChange={ (e) => setDescription(e.target.value) }
          label={ `${ translate("Description") }` }
          placeholder={ translate("Enter Description") }
        />
        <Input
          value={ subject }
          onChange={ (e) => setSubject(e.target.value) }
          label={ `${ translate("Subject Line") }*` }
          placeholder={ translate("Enter Subject") }
        />
        <div>
          <RichTextEditor editorContent={ editorContent } setEditorContent={ setEditorContent } />
        </div>
      </div>
    </Modal>
  )
}

import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import "./group-accounts-body.sass"

import {
  DataGridPro,
  GridCellParams,
  GridColDef,
  GridTreeNode,
  GridValidRowModel,
} from "@mui/x-data-grid-pro"
import { EditOutlined } from "@mui/icons-material"
import TuneIcon from "@mui/icons-material/Tune"
import LabelImportantIcon from "@mui/icons-material/LabelImportant"
import { useParams } from "react-router-dom"
import SearchBar from "../SearchBar"

import { useSelector } from "../../state/hooks"
import IconButton from "../IconButton"
import Dropdown from "../Dropdown"
import FilterMenu, { FilterMenuValue } from "../FilterMenu"
import Button from "../Button"
import { RootState, store } from "../../state/store"
import { getGroupAccounts } from "../../state/communicationsPage"
import * as API from "../../util/apiClient"
import { getColumnHeaders, getRowData } from "./GroupAccountsTableContent"

type TableDataGridParams = GridCellParams<any, unknown, unknown, GridTreeNode>

export default function GroupAccountsBody() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.GroupAccountsBody" })
  const { commGroupID } = useParams()
  const [ searchInputValue, setSearchInputValue ] = useState("")
  const [ filterValue, setFilterValue ] = useState({} as FilterMenuValue)
  const { communicationGroupAccounts } = useSelector((root: RootState) => root.communicationsPage)
  const tableHeaders: GridColDef[] = getColumnHeaders(translate)

  useEffect(() => {
    store.dispatch(getGroupAccounts({
      startsWith: searchInputValue,
      communicationGroupId: `${ commGroupID }`,
    }))
  }, [ searchInputValue, filterValue ])

  const onSearchInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Placeholder
    setSearchInputValue(event.target.value)
  }

  const onEditClick = () => {
    // Placeholder
  }

  const onFilterClick = () => {
    // Placeholder
  }

  const onAddAccountClick = () => {
    // Placeholder
  }

  const cellClickAction = ({ field, row }: TableDataGridParams) => {
    if (field === "contactInfo") {
      // Display a modal

      // eslint-disable-next-line no-console
      console.log(row)
    }
  }

  const rows: (GridValidRowModel | null)[] = (API.isSuccess(communicationGroupAccounts))
    ? communicationGroupAccounts.payload.searchCommunicationGroupNetworkAccount.rows.map((row) => getRowData(row)) : []

  return (
    <div className="cp_component_communications-body-main-container">
      <div className="header-section">
        <SearchBar className="search-bar" onChange={ (event) => onSearchInput(event) } />
        <div className="details-container">
          <IconButton onClick={ onFilterClick }>
            <TuneIcon />
          </IconButton>
          <Dropdown
            buttonType="custom"
            customButtonChildren={ <LabelImportantIcon className="important-label-button" /> }
          >
            <FilterMenu
              filterOptions={ [] }
              filterValue={ filterValue }
              setFilterValue={ setFilterValue }
            />
          </Dropdown>
          <IconButton onClick={ onEditClick }>
            <EditOutlined />
          </IconButton>
          <Button
            className="add-account-button"
            label={ translate("+ ADD ACCOUNT") }
            onClick={ onAddAccountClick }
          />
        </div>
      </div>
      <div className="communications-body">
        <DataGridPro
          checkboxSelection={ true }
          columns={ tableHeaders }
          disableRowSelectionOnClick={ true }
          hideFooter={ true }
          rowHeight={ 80 }
          rows={ rows }
          loading={ (communicationGroupAccounts === "init" || communicationGroupAccounts === "loading") }
          pinnedColumns={ {
            left: [ "__check__", "account" ],
            right: [ "groupsContextMenu" ],
          } }
          onCellClick={ cellClickAction }
        />
      </div>
    </div>
  )
}

import React, { useEffect } from "react"
import { EditorContent, useEditor } from "@tiptap/react"
import "./style.sass"
import StarterKit from "@tiptap/starter-kit"
import Underline from "@tiptap/extension-underline"
import TextAlign from "@tiptap/extension-text-align"
import Image from "@tiptap/extension-image"
import Link from "@tiptap/extension-link"
import MenuBar from "./MenuBar"

type Props = {
  editorContent: string
  setEditorContent: React.Dispatch<React.SetStateAction<string>>
}

export default function RichTextEditor({ editorContent, setEditorContent }: Props) {
  const editor = useEditor({
    content: editorContent,
    extensions: [
      StarterKit,
      Underline,
      TextAlign.configure({
        types: [ "heading", "paragraph" ],
      }),
      Image,
      Link.configure({
        openOnClick: false,
        autolink: true,
        defaultProtocol: "https",
      }),
    ],
    onUpdate: (editorState) => { setEditorContent(editorState.editor.getHTML()) },
  })

  // Ensure editor content is updated when editorContent prop changes
  useEffect(() => {
    if (editor && editorContent !== editor.getHTML()) {
      editor.commands.setContent(editorContent)
    }
  }, [ editor, editorContent ])

  if (!editor) return null

  return (
    <div className="cp_component-rich-text-editor">
      <MenuBar editor={ editor } />
      <EditorContent className="text-editor-section" editor={ editor } />
    </div>
  )
}

import React, { useEffect } from "react"
import { useParams } from "react-router-dom"
import { useSelector, useDispatch } from "../../state/hooks"
import * as API from "../../util/apiClient"
import * as GraphQL from "../../graphql"
import Checkbox from "../Checkbox"
import LoadingIndicatorCard from "../LoadingIndicatorCard"
import ErrorHandler from "../ErrorHandler"
import InfiniteScroll from "../InfiniteScroll"
import {
  fetchEmailsList,
  resetConversations,
  resetPage,
  setPage,
} from "../../state/commEmailListView"
import { formatTimestampToCustomDate, limitTextLength } from "../../util/miscHelper"
import { DEFAULT_CONVERSATION_QUERY } from "../../util/constant"

import "./communications-email-list-view.sass"

interface Conversation {
  id: string,
  handleName: string,
  date: string,
  subject: string,
  body: string,
}

export default function CommunicationsEmailListView() {
  const { commGroupID } = useParams()
  const dispatch = useDispatch()
  const { conversations, page } = useSelector(({ commEmailListViewSlice }) => commEmailListViewSlice)
  const [ processedConversations, setProcessedConversations ] = React.useState<Conversation[]>([])

  const fetchConversations = () => {
    if (commGroupID) {
      const conversationInfo: GraphQL.SearchConversationsQueryVariables = {
        communicationGroupId: commGroupID,
        startsWith: DEFAULT_CONVERSATION_QUERY.STARTS_WITH,
        page,
        limit: DEFAULT_CONVERSATION_QUERY.LIMIT,
        filter: DEFAULT_CONVERSATION_QUERY.FILTER,
        batchId: DEFAULT_CONVERSATION_QUERY.BATCH_ID,
      }
      dispatch(fetchEmailsList(conversationInfo))
      dispatch(setPage())
    }
  }

  useEffect(() => {
    if (conversations === "init") {
      fetchConversations()
    }

    return () => {
      dispatch(resetConversations())
      dispatch(resetPage())
    }
  }, [])

  const processConversations = (rows: GraphQL.ConversationRowFragment[]) => {
    const currConversations = [ ...processedConversations ]
    const nextConversations = rows.map((conversation) => ({
      id: conversation.id,
      handleName: conversation.socialAccount?.userName,
      date: formatTimestampToCustomDate(conversation.conversationThread?.lastMessage?.created),
      subject: conversation.subject,
      body: limitTextLength(conversation.conversationThread?.lastMessage?.text, 140),
    }))
    setProcessedConversations([ ...currConversations, ...nextConversations ])
  }

  useEffect(() => {
    if (conversations === "init" || conversations === "loading") return

    if (API.isSuccess(conversations)) {
      const onlyConversations = conversations.payload.searchConversation.rows
        .filter((conversation) => conversation.__typename === "Conversation")

      processConversations(onlyConversations as GraphQL.ConversationRowFragment[])
    }
  }, [ conversations ])

  if (conversations === "init" || conversations === "loading") {
    return (
      <div className="communications-email-list-view loading">
        <LoadingIndicatorCard />
      </div>
    )
  }

  if (conversations.status === "error") {
    return (
      <div className="communications-email-list-view error">
        <ErrorHandler />
      </div>
    )
  }

  return (
    <div className="communications-email-list-view">
      <section className="email-list-heading"><Checkbox /></section>
      <InfiniteScroll
        dataLength={ processedConversations.length }
        next={ fetchConversations }
        hasMore={ conversations.payload.searchConversation.totalCount > processedConversations.length }
      >
        <section className="email-list-emails">
          { processedConversations.map((conversation) => (
            <div key={ conversation.id } className="email-list-email">
              <div className="email-list-email-selector">
                <Checkbox />
              </div>
              <div className="email-content">
                <div className="email-content-top">
                  <p className="handle-name">{ conversation.handleName }</p>
                  <p className="date">{ conversation.date }</p>
                </div>
                <p className="subject">{ conversation.subject }</p>
                <p className="body">{ conversation.body }</p>
              </div>
            </div>
          )) }
        </section>
      </InfiniteScroll>
    </div>
  )
}

import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { Dispatch } from "redux"
import { Status } from "../../util/types"
import * as GraphQL from "../../graphql"
import * as API from "../../util/apiClient"

// Communication Group Page Slice Interface and Initial State
export interface CommunicationsPageState {
  communicationGroup: Status<GraphQL.GetCommunicationGroupPageQuery>,
  communicationGroupAccounts: Status<GraphQL.SearchGroupAccountsByCommGroupIdQuery>,
}

const initialState: CommunicationsPageState = {
  communicationGroup: "init",
  communicationGroupAccounts: "init",
}

// Campaign Page Slice
export const CommunicationsPageSlice = createSlice({
  name: "CommunicationsPageSlice",
  initialState,
  reducers: {
    setCommunicationGroup: (
      state,
      action: PayloadAction<Status<GraphQL.GetCommunicationGroupPageQuery>>,
    ) => ({
      ...state,
      communicationGroup: action.payload,
    }),
    setGroupAccounts: (
      state,
      action: PayloadAction<Status<GraphQL.SearchGroupAccountsByCommGroupIdQuery>>,
    ) => ({
      ...state,
      communicationGroupAccounts: action.payload,
    }),
  },
})

export const {
  setCommunicationGroup,
  setGroupAccounts,
} = CommunicationsPageSlice.actions
export default CommunicationsPageSlice.reducer

export const getCommunicationGroup = (
  variables: GraphQL.GetCommunicationGroupQueryVariables,
) => async (dispatch: Dispatch) => {
  dispatch(setCommunicationGroup("loading"))

  const result = await API.getCommunicationGroup(variables)

  dispatch(setCommunicationGroup(result))
}

export const getGroupAccounts = (
  variables: GraphQL.SearchGroupAccountsByCommGroupIdQueryVariables,
) => async (dispatch: Dispatch) => {
  // Indicate that we're loading
  dispatch(setGroupAccounts("loading"))

  // Do the query
  const result = await API.searchGroupAccountsByCommGroupId(variables)

  // Set results
  dispatch(setGroupAccounts(result))
}
